import { ArrowBack, CheckCircle, CropFree, KeyboardArrowDown } from '@mui/icons-material';
import { Box, Button, capitalize, Divider, IconButton, ListItemText, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { format, formatISO, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

import { ImageView, InnerLink, Modal } from '@/components/common';
import { WarningBox } from '@/components/common/Modal/styled';
import { StatusChip } from '@/components/styled/Chip';
import { ContextMenu, MenuTitle } from '@/components/styled/Menu';
import { ROUTES } from '@/constants';
import { actionConfirmation, ConfirmModal, EActionConfirm } from '@/constants/actionConfirm';
import { banTime } from '@/constants/banTime';
import { useAppDispatch } from '@/hooks';
import { ToastService } from '@/services';
import { showSpinner } from '@/store/actions';
import { archiveReport, banInApp, banUser, deleteMessage, deleteReport, getReportById as getReportByIdAction, ignoreReport, kickUser, restoreArchivedReport } from '@/store/thunks';
import { theme } from '@/theme';
import { ReportStatusColor } from '@/theme/theming/statusesTheme';
import { ChatType, EChatType, EReportObject, EReportStatusSend, EReportTabType, ESolution, Report } from '@/types';

import WarningSign from '../../../../assets/pictures/PopupBadge.svg';
import { TableWrapper } from '../../styled/TableWrapper';
import { StatusName } from '../Reports/constants/sortModels';
import { ReportHistory } from './ReportHistory';
import { ActionBox, ActionButton, CancelButton, ColumnDetail, ColumnTitle, ConfirmButton, ConfirmText, ConfirmTitle, HeaderOutContainer, InfoBox, MessageBox, MessageImage, ResizeBlock, Solution, SolutionBox, SolutionText } from './styled/ReportDetail';


export const ReportDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [report, setReport] = useState<Report | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [modal, setModal] = useState<ConfirmModal>(
    actionConfirmation[EActionConfirm.UNBAN]
  );
  const isPreMediumScreen = useMediaQuery('(max-width: 1100px)')
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [report?.id]);

  useEffect(() => {
    if (id) {
      dispatch(showSpinner(true));
      dispatch(getReportByIdAction(id))
        .unwrap()
        .then((currReport) => {
          setReport(currReport);
        })
        .catch((err) => {
          ToastService.error(err.message);
        })
        .finally(() => {
          dispatch(showSpinner(false));
        });
    }
  }, [dispatch, id]);

  const ignore = useCallback(() => {
    if (report) {
      dispatch(ignoreReport(report.id))
        .unwrap()
        .then(() => {
          setReport({
            ...report,
            status: EReportStatusSend.RESOLVED,
            solution: ESolution.IGNORED,
          });
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    }
  }, [dispatch, report]);

  const kick = useCallback(() => {
    if (report) {
      dispatch(kickUser(report.id))
        .unwrap()
        .then(() => {
          setReport({
            ...report,
            status: EReportStatusSend.RESOLVED,
            solution: ESolution.KICKED,
          });
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    }
  }, [dispatch, report]);

  const block = useCallback(() => {
    if (report) {
      dispatch(banUser({ id: report.id, duration: null }))
        .unwrap()
        .then(() => {
          setReport({
            ...report,
            status: EReportStatusSend.RESOLVED,
            solution: ESolution.BANNED_FROM_CHAT,
          });
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    }
  }, [dispatch, report]);

  const banFromApp = useCallback(() => {
    if (report) {
      dispatch(banInApp({ id: report.id }))
        .unwrap()
        .then(() => {
          setReport({
            ...report,
            status: EReportStatusSend.RESOLVED,
            solution: ESolution.BANNED_FROM_APP,
          });
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    }
  }, [report, dispatch]);

  const banChatTemporary = useCallback(
    (time: number) => {
      if (report) {
        dispatch(banUser({ id: report.id, duration: time }))
          .unwrap()
          .then(() => {
            setReport({
              ...report,
              status: EReportStatusSend.RESOLVED,
              solution: ESolution.BANNED_FROM_CHAT,
            });
          })
          .catch((err) => {
            ToastService.error(err.message);
          });
      }
    },
    [report, dispatch]
  );

  const banChat = useCallback(() => {
    if (report) {
      dispatch(banUser({ id: report.id, duration: null }))
        .unwrap()
        .then(() => {
          setReport({
            ...report,
            status: EReportStatusSend.RESOLVED,
            solution: ESolution.BANNED_FROM_CHAT,
          });
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    }
  }, [report, dispatch]);

  const archive = useCallback(() => {
    if (report) {
      dispatch(archiveReport(report.id))
        .unwrap()
        .then(() => {
          setReport({
            ...report,
            archivedAt: formatISO(new Date()),
          });
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    }
  }, [dispatch, report]);

  const deleteRep = useCallback(() => {
    if (report) {
      dispatch(deleteReport(report.id))
        .unwrap()
        .then(() => {
          ToastService.success('Report successfuly deleted');
          navigate(ROUTES.DASHBOARD.REPORTS);
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    }
  }, [report, dispatch, navigate]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const closeMenu = useCallback(() => {
    setOpen(false);
  }, []);

  const deleteMsg = useCallback(() => {
    if (report) {
      dispatch(deleteMessage(report?.id))
        .unwrap()
        .then(() => {
          setReport({
            ...report,
            status: EReportStatusSend.RESOLVED,
            solution: ESolution.MESSAGE_DELETED,
          });
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    }
  }, [report, dispatch]);

  const openConfirmModal = useCallback((action: EActionConfirm) => {
    setModal(actionConfirmation[action]);
    setIsOpenModal(true);
  }, []);

  const restore = useCallback(() => {
    if (report) {
      dispatch(restoreArchivedReport(report.id))
        .unwrap()
        .then(() => {
          setReport({ ...report, archivedAt: undefined });
        })
        .catch((err) => {
          ToastService.error(err.message);
        });
    }
  }, [dispatch, report]);

  const renderMessageActionBox = useCallback(() => {
    if (report?.solution) {
      return (
        <ActionBox>
          {report.solution === ESolution.KICKED ? (
            <ActionButton
              variant="outlined"
              disabled
              startIcon={<CheckCircle />}
            >
              Kicked
            </ActionButton>
          ) : report.solution === ESolution.IGNORED ? (
            <ActionButton
              variant="outlined"
              disabled
              startIcon={<CheckCircle />}
            >
              Ignored
            </ActionButton>
          ) : report.solution === ESolution.BANNED_FROM_CHAT ? (
            <SolutionBox>
              <Solution>SOLUTION</Solution>
              <SolutionText>User was Banned from the Chat</SolutionText>
            </SolutionBox>
          ) : report.solution === ESolution.BANNED_FROM_APP ? (
            <SolutionBox>
              <Solution>SOLUTION</Solution>
              <SolutionText>User was Banned from the App</SolutionText>
            </SolutionBox>
          ) : null}
          {report.solution !== ESolution.MESSAGE_DELETED ? (
            <Divider sx={{ margin: isSmallScreen ? '0' : '20px 0 10px' }} />
          ) : null}
          {report.archivedAt ? (
            <>
              <ActionButton
                variant="outlined"
                onClick={() => openConfirmModal(EActionConfirm.DELETE_REPORT)}
              >
                Delete Report
              </ActionButton>
              <ActionButton variant="outlined" onClick={() => restore()}>
                Restore Report
              </ActionButton>
            </>
          ) : (
            <ActionButton
              className={report.solution === ESolution.BANNED_FROM_APP || report.solution === ESolution.BANNED_FROM_CHAT ? 'full-size' : undefined}
              variant="outlined"
              onClick={() => archive()}
            >
              Archive Report
            </ActionButton>
          )}
        </ActionBox>
      );
    }
    if (report?.status === EReportStatusSend.NEW) {
      return (
        <ActionBox>
          {report.target !== EChatType.P2P && (
            <>
              <ActionButton
                variant="contained"
                onClick={kick}
                sx={{
                  background: theme.palette.warning.dark,
                  '&:hover': {
                    background: theme.palette.warning.main,
                  },
                }}
              >
                Kick
              </ActionButton>
              <ActionButton
                variant="contained"
                onClick={handleOpen}
                endIcon={<KeyboardArrowDown />}
                sx={{
                  background: theme.palette.warning.dark,
                  '&:hover': {
                    background: theme.palette.warning.main,
                  },
                  padding: '8px',
                }}
              >
                Ban From The chat
              </ActionButton>
              <ContextMenu
                id={`context-menu-detail-${report.id}`}
                open={open}
                anchorEl={anchorEl}
                onClose={() => closeMenu()}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <MenuItem onClick={() => banChat()}>
                  <ListItemText>Permanent Ban</ListItemText>
                </MenuItem>
                <Divider sx={{ marginBottom: '10px' }} />
                <MenuTitle>Temporary ban</MenuTitle>
                {banTime.map(({ value, label }) => (
                  <MenuItem
                    key={`tmp-ban-${value}`}
                    onClick={() => banChatTemporary(value)}
                  >
                    <ListItemText>{label}</ListItemText>
                  </MenuItem>
                ))}
              </ContextMenu>
              <Divider sx={{ marginY: isSmallScreen ? '0' : '20px' }} />
            </>
          )}
          <>
            <ActionButton
              variant="contained"
              color="error"
              onClick={() => openConfirmModal(EActionConfirm.BAN_APP)}
              className='noo-margin'
            >
              Ban From The App
            </ActionButton>

            <ActionButton variant="contained" onClick={ignore}>
              Ignore
            </ActionButton>
          </>
        </ActionBox>
      );
    }
    return null;
  }, [
    anchorEl,
    archive,
    banChat,
    banChatTemporary,
    closeMenu,
    ignore,
    isSmallScreen,
    kick,
    open,
    openConfirmModal,
    report?.archivedAt,
    report?.id,
    report?.solution,
    report?.status,
    report?.target,
    restore
  ]);

  const renderUserActionBox = useCallback(() => {
    if (report?.solution) {
      return (
        <ActionBox>
          {report.solution === ESolution.KICKED ? (
            <ActionButton
              variant="outlined"
              disabled
              startIcon={<CheckCircle />}
            >
              Blocked
            </ActionButton>
          ) : report.solution === ESolution.IGNORED ? (
            <ActionButton
              variant="outlined"
              disabled
              startIcon={<CheckCircle />}
            >
              Ignored
            </ActionButton>
          ) : report.solution === ESolution.BANNED_FROM_APP ? (
            <SolutionBox>
              <Solution>SOLUTION</Solution>
              <SolutionText>User was Banned from the App</SolutionText>
            </SolutionBox>
          ) : null}
          {report.solution !== ESolution.MESSAGE_DELETED ? (
            <Divider sx={{ margin: isSmallScreen ? '0' : '20px 0 10px' }} />
          ) : null}
          {report.archivedAt ? (
            <ActionButton
              variant="outlined"
              onClick={() => openConfirmModal(EActionConfirm.DELETE_REPORT)}
              className={report.solution === ESolution.BANNED_FROM_APP ? 'full-size' : undefined}
            >
              Delete Report
            </ActionButton>
          ) : (
            <ActionButton className={report.solution === ESolution.BANNED_FROM_APP ? 'full-size' : undefined} variant="outlined" onClick={() => archive()}>
              Archive Report
            </ActionButton>
          )}
        </ActionBox>
      );
    }
    if (report?.status === EReportStatusSend.NEW) {
      return (
        <ActionBox>
          {report.target !== EChatType.P2P && (
            <>
              <ActionButton
                variant="contained"
                onClick={block}
                sx={{
                  background: theme.palette.warning.dark,
                  '&:hover': {
                    background: theme.palette.warning.main,
                  },
                }}
                className='full-size'
              >
                Block
              </ActionButton>
              <Divider sx={{ marginY: '20px' }} />
            </>
          )}
          <>
            <ActionButton
              variant="contained"
              color="error"
              onClick={() => openConfirmModal(EActionConfirm.BAN_APP)}
            >
              Ban From The App
            </ActionButton>

            <ActionButton variant="contained" onClick={ignore}>
              Ignore
            </ActionButton>
          </>
        </ActionBox>
      );
    }
    return null;
  }, [
    archive,
    block,
    ignore,
    openConfirmModal,
    report?.archivedAt,
    report?.solution,
    report?.status,
    report?.target,
    isSmallScreen,
  ]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const modalClose = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  const openImageView = useCallback(() => {
    setIsOpenImage(true);
  }, []);

  const closeImageView = useCallback(() => {
    setIsOpenImage(false);
  }, []);

  const confirmActionHandle = useCallback(
    (action: EActionConfirm) => {
      switch (action) {
        case EActionConfirm.BAN_APP: {
          banFromApp();
          break;
        }
        case EActionConfirm.DELETE_MESSAGE: {
          deleteMsg();
          break;
        }
        case EActionConfirm.DELETE_REPORT: {
          deleteRep();
          break;
        }
        default:
          break;
      }
      modalClose();
    },
    [banFromApp, deleteMsg, deleteRep, modalClose]
  );

  return (
    <Box>
      <Helmet>
        <title>Report Details</title>
      </Helmet>
      <HeaderOutContainer>
        <IconButton aria-label="back" onClick={goBack}>
          <ArrowBack />
        </IconButton>
        <Typography component="h5" fontSize={24} sx={{ marginX: '10px' }}>
          Report Details
        </Typography>
        {report ? (
          <StatusChip
            sx={{
              marginLeft: 0,
              backgroundColor:
                ReportStatusColor[
                  report?.archivedAt
                    ? EReportTabType.ARCHIVED
                    : report?.status === EReportStatusSend.NEW
                    ? EReportTabType.ACTIVE
                    : EReportTabType.RESOLVED
                ],
              '.MuiChip-icon': {
                color: theme.palette.neutral?.main,
              },
            }}
            label={report.archivedAt ? 'Archived' : StatusName[report.status]}
            icon={
              report?.status === EReportStatusSend.RESOLVED &&
              !report.archivedAt ? (
                <CheckCircle />
              ) : undefined
            }
          />
        ) : null}
      </HeaderOutContainer>
      {report ? (
        <TableWrapper
          display="flex"
          flexDirection={isPreMediumScreen ? "column" : "row"}
          padding="16px"
          justifyContent="space-between"
        >
          <InfoBox>
            <Box
              display="flex"
              flexWrap={isMediumScreen ? 'wrap' : 'nowrap'}
              marginBottom="20px"
              justifyContent={isMediumScreen ? 'space-between' : 'flex-start'}
            >
              <ColumnDetail>
                <ColumnTitle>Date</ColumnTitle>
                <Typography>
                  {report.createdAt
                    ? format(parseISO(report.createdAt), 'dd/MM/yy')
                    : '--/--/--'}
                </Typography>
              </ColumnDetail>
              <ColumnDetail>
                <ColumnTitle>Report Object</ColumnTitle>
                <Typography>
                  {report.context?.messageId
                    ? EReportObject.MESSAGE
                    : EReportObject.USER}
                </Typography>
              </ColumnDetail>
              {report.context?.messageId ? (
                <ColumnDetail minWidth="120px">
                  <ColumnTitle>Type</ColumnTitle>
                  <Typography>
                    {ChatType[report.target as EChatType]}
                  </Typography>
                </ColumnDetail>
              ) : null}
              <ColumnDetail className="report-id" flexGrow={isMediumScreen ? 0 : 1}>
                <ColumnTitle>ReportID</ColumnTitle>
                <Typography>{report.id}</Typography>
              </ColumnDetail>
              <ColumnDetail flexGrow={isMediumScreen ? 0 : 1}>
                <ColumnTitle>Reason</ColumnTitle>
                <Typography>
                  {capitalize(report.reason.replace('-', ' '))}
                </Typography>
              </ColumnDetail>
            </Box>
            <Divider />
            <Box display="flex" marginY="20px">
              {report.sender ? (
                <Box
                  display="flex"
                  flexWrap={isMediumScreen ? 'wrap' : 'nowrap'}
                  justifyContent={isMediumScreen ? 'space-between' : 'flex-start'}
                  width='100%'
                >
                  <ColumnDetail flexGrow={isMediumScreen ? 0 : 1}>
                    <ColumnTitle>Reported By</ColumnTitle>
                    <InnerLink
                      style={{ color: theme.palette.primary.main }}
                      to={`${ROUTES.DASHBOARD.USERS}/${report.sender.id}`}
                    >
                      <Typography>{report.sender.username}</Typography>
                    </InnerLink>
                  </ColumnDetail>
                  {report.context?.messageId ? (
                    <ColumnDetail flexGrow={isMediumScreen ? 0 : 1}>
                      <ColumnTitle>Chat Name</ColumnTitle>
                      <Typography>
                        {report.targetChat?.chatName || '-'}
                      </Typography>
                    </ColumnDetail>
                  ) : null}

                  <ColumnDetail flexGrow={isMediumScreen ? 0 : 1}>
                    <ColumnTitle>ID</ColumnTitle>
                    <Typography style={{ wordBreak: "break-all" }}>{report?.sender?.id}</Typography>
                  </ColumnDetail>
                </Box>
              ) : (
                <Box
                  display="flex"
                  justifyContent={isMediumScreen ? 'space-between' : 'flex-start'}
                  width='100%'
                >
                  <ColumnDetail flexGrow={isMediumScreen ? 0 : 1}>User was deleted</ColumnDetail>
                  {report.context?.messageId ? (
                    <ColumnDetail flexGrow={isMediumScreen ? 0 : 1}>
                      <ColumnTitle>Chat Name</ColumnTitle>
                      <Typography>
                        {report.targetChat?.chatName || '-'}
                      </Typography>
                    </ColumnDetail>
                  ) : null}
                </Box>
              )}
            </Box>
            <Divider />
            <Box display="flex" marginY="20px">
              {report.user ? (
                <Box
                  display="flex"
                  flexWrap={isMediumScreen ? 'wrap' : 'nowrap'}
                  justifyContent={isMediumScreen ? 'space-between' : 'flex-start'}
                  width='100%'
                >
                  <ColumnDetail flexGrow={isMediumScreen ? 0 : 1}>
                    <ColumnTitle>Reported User</ColumnTitle>
                    <InnerLink
                      style={{ color: theme.palette.primary.main }}
                      to={`${ROUTES.DASHBOARD.USERS}/${report.user.id}`}
                    >
                      <Typography>{report.user.username || ''}</Typography>
                    </InnerLink>
                  </ColumnDetail>

                  <ColumnDetail flexGrow={isMediumScreen ? 0 : 1}>
                    <ColumnTitle>ID</ColumnTitle>
                    <Typography style={{ wordBreak: "break-all" }}>{report.user.id}</Typography>
                  </ColumnDetail>
                </Box>
              ) : (
                <Typography>User was deleted</Typography>
              )}
            </Box>
            {report.context?.messageId ? (
              <>
                <Box>
                  <ColumnTitle>Message</ColumnTitle>
                  {report.context?.messageText ? (
                    <Typography component="p">
                      {report.context?.messageText}
                    </Typography>
                  ) : null}
                  {report.context?.messageImage ? (
                    <MessageImage onClick={openImageView}>
                      <img
                        src={report.context?.messageImage}
                      />
                      <ResizeBlock>
                        <CropFree />
                      </ResizeBlock>
                    </MessageImage>
                  ) : null}
                </Box>
                <MessageBox>
                  {report.solution &&
                  report.solution === ESolution.MESSAGE_DELETED ? (
                    <Button
                      variant="outlined"
                      disabled
                      fullWidth
                      startIcon={<CheckCircle />}
                      sx={{ marginTop: '16px' }}
                    >
                      Deleted Message
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        openConfirmModal(EActionConfirm.DELETE_MESSAGE)
                      }
                      fullWidth
                      color="error"
                      sx={{ marginTop: '16px' }}
                    >
                      Delete Message
                    </Button>
                  )}
                </MessageBox>
              </>
            ) : null}
            { isPreMediumScreen ?
              <Divider sx={{marginBottom: "20px"}}/>
              : null
            }
          </InfoBox>
          {report.context?.messageId
            ? renderMessageActionBox()
            : renderUserActionBox()}
          <Modal isOpen={isOpenModal} hasCloseIcon onClose={modalClose}>
            <WarningBox>
              <img src={WarningSign} />
              <ConfirmTitle>{modal.title}</ConfirmTitle>
              <ConfirmText>{modal.text(report.user?.username)}</ConfirmText>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CancelButton
                  className="cancel"
                  variant="contained"
                  onClick={() => modalClose()}
                >
                  Cancel
                </CancelButton>
                <ConfirmButton
                  variant="contained"
                  onClick={() => confirmActionHandle(modal.action)}
                  color={
                    modal.action === EActionConfirm.UNBAN ? 'primary' : 'error'
                  }
                >
                  {modal.activeButton}
                </ConfirmButton>
              </Box>
            </WarningBox>
          </Modal>
          { report.context?.messageId && report.context?.messageImage ?
            <ImageView
              onClose={closeImageView}
              isOpen={isOpenImage}
              picture={report.context?.messageImage}
            />
            : null
          }
        </TableWrapper>
      ) : null}
      {report?.user ? (
        <Box marginTop="20px">
          <ReportHistory
            key="user-report-history"
            historyType={EReportObject.USER}
            report={report}
            mainId={report.user?.id.toString()}
          />
        </Box>
      ) : null}
      {report &&
        report.target !== EChatType.P2P &&
        report.context?.messageId ? (
          <Box marginTop="20px">
            <ReportHistory
              key="message-report-history"
              historyType={EReportObject.MESSAGE}
              report={report}
              mainId={report.context?.messageId.toString()}
            />
          </Box>
        ) : null
      }
    </Box>
  );
};
